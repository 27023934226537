<template>
  <div :class="$style.content">
    <div
        v-for="category in menuByCategories"
        :key="category.type"
    >
      <div :class="$style.popular">
        <h4>{{ category.type }}</h4>
      </div>
      <div :class="$style.menu">
        <MenuItem
            :class="$style.item"
            v-for="(product, index) in category.products"
            :key="index"
            :id="product.id"
            :img="product.img"
            :name="product.name"
            :price="product.price"
            v-model="product.number"
            @change="manageButton"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "@/components/MenuItem";

export default {
  name: 'App',
  components: {
    MenuItem,
  },
  data(){
    return {
      typesOrder: [
          'Популярные',
          'Суши',
          'Сеты',
          'Роллы',
      ],
    }
  },
  computed: {
    name() {
      if(window.Telegram.WebApp.initDataUnsafe.user){
        return window.Telegram.WebApp.initDataUnsafe.user.first_name
      } else {
        return 'LOCAL'
      }
    },
    menuByCategories() {
      return this.typesOrder.map((type) => {
        return {
          type,
          products: this.$store.state.menu.filter(item => item.type.includes(type))
        }
      })
    }
  },
  mounted() {
    this.manageButton()
  },
  methods: {
    manageButton() {
      const itemsCount = this.$store.state.menu.reduce((total, product) => {
        return total + product.number
      }, 0)
      if(itemsCount > 0){
        window.Telegram.WebApp.MainButton.setText(`Заказать ${itemsCount} товаров`)
        window.Telegram.WebApp.MainButton.onClick(() => { this.$router.push('/cart') })
        window.Telegram.WebApp.MainButton.show()
      } else {
        window.Telegram.WebApp.MainButton.hide()
        window.Telegram.WebApp.MainButton.onClick(() => {})
      }
    },
    
  }
}
</script>


<style lang="scss" module>

h4 {
  background: var(--tg-theme-bg-color);
}
.menu {
  display: flex;
  flex-wrap: wrap;

  .popular{
    display: flex;
  }
  .item {
    width: calc(100% / 3 - 10px)
  }
}
</style>