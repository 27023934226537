import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menu: [
      {
        id: '1',
        name: 'Гункан с креветкой',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/suchi/gunkan-krevetka1.webp'),
        type: ['Суши'],
        price: 8,
        number: 0
      },
      {
        id: '2',
        name: 'Гункан с лососем',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/suchi/gunkan-losos.webp'),
        type: ['Популярные', 'Суши'],
        price: 7.50,
        number: 0
      },
      {
        id: '3',
        name: 'Гункан с икрой тобика',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/suchi/gunkan-ikra-removebg-preview.webp'),
        type: ['Суши'],
        price: 7.50,
        number: 0
      },
      {
        id: '4',
        name: 'Гункан с тунцом',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/suchi/gunkan-tunec.webp'),
        type: ['Суши'],
        price: 7,
        number: 0
      },
      {
        id: '5',
        name: 'Гункан с чукой',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/suchi/gunkan-chuka.webp'),
        type: ['Суши'],
        price: 4,
        number: 0
      },
      {
        id: '6',
        name: 'Гункан с томаго',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/suchi/gunkan-tomago.webp'),
        type: ['Суши'],
        price: 4,
        number: 0
      },
      {
        id: '7',
        name: 'СЕТ Диамонд',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/1.webp'),
        type: ['Сеты'],
        price: 8,
        number: 0
      },
      {
        id: '8',
        name: 'СЕТ Кайфовый',
        description: 'калифорния итачи 4 шт,\n филадельфия авокадо 4 шт, \n филадельфия татаки 4 шт,\n пряный бекон 4 шт, запеченный с лососем 3 шт, запеченный с креветкой 3 шт, хот ролл с тунцом 8шт',
        img: require('../assets/2.webp'),
        type: ['Популярные', 'Сеты'],
        price: 8,
        number: 0
      },
      {
        id: '9',
        name: 'СЕТ КЛАСИК',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/3.webp'),
        type: ['Сеты'],
        price: 8,
        number: 0
      },
      {
        id: '10',
        name: 'СЕТ ТЕПЛЫЙ',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/4.webp'),
        type: ['Сеты'],
        price: 8,
        number: 0
      },
      {
        id: '11',
        name: 'Красный Дракон',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/roll/01-removebg-preview.webp'),
        type: ['Популярные', 'Роллы'],
        price: 8,
        number: 0
      },
      {
        id: '12',
        name: 'Филадельфия много лосося',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/roll/02-removebg-preview.webp'),
        type: ['Роллы'],
        price: 7.50,
        number: 0
      },
      {
        id: '13',
        name: 'Канада',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/roll/03-removebg-preview.webp'),
        type: ['Роллы'],
        price: 7.50,
        number: 0
      },
      {
        id: '14',
        name: 'Калифорния с лососем',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/roll/04-removebg-preview.webp'),
        type: ['Роллы'],
        price: 7,
        number: 0
      },
      {
        id: '15',
        name: 'Хот ролл с угрем',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/roll/05-removebg-preview.webp'),
        type: ['Роллы'],
        price: 4,
        number: 0
      },
      {
        id: '16',
        name: 'Хрустящий лосось',
        description: 'рис, угорь, лосось, огурец, икра, соус терияки, соус рофано, нори',
        img: require('../assets/roll/06-removebg-preview.webp'),
        type: ['Роллы'],
        price: 4,
        number: 0
      },
    ],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
