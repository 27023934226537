<template>
  <div :class="$style.card">
    <el-badge
        :class="$style.number"
        :value="number"
        :hidden="isBadgeHidden"
        type="warning"
    >
      <div :class="$style.info" @click="$router.push(`/product/${id}`)">
        <img :src="img" width="100%">
        <div :class="$style.infoname">{{ name }}</div>
        <div :class="$style.infoprice">{{ price }} BYN</div>
      </div>
    </el-badge>
      <div :class="$style.actions">
        <el-button v-if="number < 1" size="mini" @click="add" type="warning">Добавить</el-button>
        <template v-else>
          <el-button size="mini" type="danger" @click="decrease"> - </el-button>
          <el-button size="mini" type="warning" @click="increase"> + </el-button>
        </template>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    id: String,
    img: String,
    name: String,
    number: Number,
    price: Number
  },
  model: {
    prop: 'number',
    event: 'input'
  },
  computed: {
    isBadgeHidden() {
      return !this.number
    }
  },
  methods: {
    add(){
      this.$emit('input', 1)
      this.$emit('change', 1)
    },
    decrease(){
      const newNumber = this.number - 1
      this.$emit('input', newNumber)
      this.$emit('change', newNumber)
    },
    increase(){
      const newNumber = this.number + 1
      this.$emit('input', newNumber)
      this.$emit('change', newNumber)
    }
  }
}
</script>

<style lang="scss" module>
.card {
  margin: 5px;
  // padding: 5px;
  // background: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  .info{

    img{
      border-radius: 15px;
    }
    .infoname{
      text-align: center;
      color: var(--tg-theme-text-color);
    }
    .infoprice{
      color: #09B24D;
      font-weight: 700;
      text-align: center;
    }
  }
  .actions {
    display: flex;
    button {
      flex: 1;
      border-radius: 10px;
      font-size: 12px;

    
      

    }
  }
}
</style>